import * as t from 'io-ts'

import { ISizeAdvisorWidget } from '@root/types/widget'
import React, { Suspense } from 'react'
import {
  Config as SizeAdvConfig,
  ConfigCodec as SizeAdvConfigCodec,
  ConfigWithCallbacks as SizeAdvConfigWithCallbacks,
} from '@root/types/sizeadvisorconfig'
import { closeApp } from '@root/utils/app'

import ReactDOM from 'react-dom'
import { Spinner } from '@components/Spinner'
import { fold } from 'fp-ts/lib/Either'
import { formatValidationErrors } from 'io-ts-reporters'
import { getSuggestedSizeLabels } from '@root/utils/sizeadvisor'
import { logger } from '@root/utils/logger'
import { pipe } from 'fp-ts/lib/function'
import pkgVersion from '../../version'

const SizeAdvisor = React.lazy(() => import('./SizeAdvisor'))

if (process.env.REACT_APP_DEBUG === 'true') {
  localStorage.debug = 'FA:*'
}

export default class SizeAdvisorWidget implements ISizeAdvisorWidget {
  config?: SizeAdvConfigWithCallbacks

  constructor(config: SizeAdvConfigWithCallbacks) {
    this.config = config

    // set default values
    if (typeof this.config.measurementUnits === 'undefined') {
      this.config.measurementUnits = ['mm']
    }

    logger.debug(config, 'configuration')
  }

  async render() {
    if (!this.config) {
      throw new Error('Config is not defined')
    }

    const { selector } = this.config
    const container = document.querySelector(selector)
    if (!container) {
      throw new Error(
        `You requested Size Advisor to render inside the element with the selector ${selector}, but there is no such element in the document. Check the "selector" parameter in Frame Advisor initialization or your DOM.`,
      )
    }

    ReactDOM.render(
      <Suspense fallback={<Spinner />}>
        <SizeAdvisor config={this.config} />
      </Suspense>,
      container,
    )
  }

  async closeApp() {
    closeApp(this.config)
  }

  static getSuggestedSizes(
    hinge: number,
    sizes: { [key: string]: number },
    lowerBound = 3,
    upperBound = 4,
  ) {
    return getSuggestedSizeLabels({
      sizes,
      optimalHinge: hinge,
      lowerBound,
      upperBound,
    })
  }

  static version() {
    return pkgVersion
  }

  static new(config: SizeAdvConfigWithCallbacks) {
    const onLeft = (errors: t.Errors) => {
      const errorsToReport = formatValidationErrors(errors)
      throw Error(
        `Size Advisor was initialized with a wrong configuration object, ${
          errorsToReport.length
        } errors found:\n - ${errorsToReport.join('\n - ')}.`,
      )
    }

    const onRight = (config: SizeAdvConfig) =>
      new SizeAdvisorWidget(config as SizeAdvConfigWithCallbacks)

    return pipe(SizeAdvConfigCodec.decode(config), fold(onLeft, onRight))
  }
}
